import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js";

export default class extends Controller {
  static get targets() {
    return ["list"];
  }

  static values = {
    options: {
      type: Object,
      default: {}
    }
  }

  connect() {
    this.sortable = Sortable.create(this.listTarget, {
        ...this.options(),
    });
  }

  update(event) {
    const response = patch(event.item.dataset.updateUrl, {
      body: JSON.stringify({
        'position': this.setPosition(event)
      }),
      contentType: 'application/json',
      responseKind: 'turbo-stream'
    });
  }

  formData(event) {
    const data = new FormData();
    data.append("position", this.setPosition(event));
    return data;
  }

  setPosition(event) {
    return event.newIndex;
  }

  options() {
    const customOptions = this.element.dataset.options
    const baseOptions = {
      animation: 300,
      ghostClass: 'is-blue-1',
      handle: ".handle",
      dragClass: "dragging", // use this to style the item being dragged
      onEnd: this.update.bind(this)
    }

    Object.assign(this.optionsValue, baseOptions)

    if (customOptions != null || customOptions != undefined) {
      Object.assign(this.optionsValue, JSON.parse(customOptions))
    }

    return this.optionsValue
  }
}
