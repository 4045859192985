import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class SelectOrderController extends Controller {
  static get targets() {
    return ["responseDropTarget", "responseList", "answerDropTarget", "answerList"];
  }

  get assessmentsController() {
    const e = document.getElementById('assessment');
    return this.application.getControllerForElementAndIdentifier(e, "assessments");
  }

  connect() {
    this.response_sortable = Sortable.create(this.responseListTarget, {
      dataIdAttr: "data-sortable-id",
      animation: 300,
      ghostClass: "is-blue-1",
      handle: ".handle",
      dragClass: "dragging",
      group: "sortable-list",
    });

    this.answer_sortable = Sortable.create(this.answerListTarget, {
      dataIdAttr: "data-sortable-id",
      animation: 300,
      ghostClass: "is-blue-1",
      handle: ".handle",
      dragClass: "dragging",
      group: "sortable-list",
    });
  }

  updateResponseList() {
    this.hideResponseDropTarget();
    this.showAnswerDropTarget();
    this.addValues(this.responseListTarget.getElementsByTagName("li"));
    this.toggleSubmit();
  }

  updateAnswerList() {
    this.hideAnswerDropTarget();
    this.showResponseDropTarget();
    this.removeValues(this.answerListTarget.getElementsByTagName("li"));
    this.toggleSubmit();
  }

  hideResponseDropTarget() {
    this.responseDropTargetTarget.classList.add("is-hidden");
  }

  showResponseDropTarget() {
    if (this.responseListTarget.getElementsByTagName("li").length <= 0) {
      this.responseDropTargetTarget.classList.remove("is-hidden");
    }
  }

  hideAnswerDropTarget() {
    this.answerDropTargetTarget.classList.add("is-hidden");
  }

  showAnswerDropTarget() {
    if (this.answerListTarget.getElementsByTagName("li").length <= 0) {
      this.answerDropTargetTarget.classList.remove("is-hidden");
    }
  }

  addValues(response) {
    // iterate through all li elements in the response list
    for (let i = 0; i < response.length; i++) {
      // add the answer id to the input value for each selected response
      response[i].getElementsByTagName("input")[0].value = response[i].id
    }
  }

  removeValues(answerBank) {
    // iterate through all the li elements in the answer bank list
    for (let i = 0; i < answerBank.length; i++) {
      // clear the answer id from the input value for each non-selected answer
      answerBank[i].getElementsByTagName("input")[0].value = ""
    }
  }

  toggleSubmit() {
    if (this.assessmentsController) {
      if (this.responseListTarget.getElementsByTagName("li").length <= 0) {
        this.assessmentsController.disableSubmit();
      } else {
        this.assessmentsController.enableSubmit();
      }
    }
  }

  keyPress(event) {
    if (["ArrowUp", "ArrowDown"].includes(event.code) && document.activeElement.tagName == "LI") {
      this.arrowKeyPress(event);
      event.preventDefault(); // prevent scrolling
    } else if (event.code == "Space" && document.activeElement.tagName == "LI") {
      this.spaceKeyPress(event);
      event.preventDefault(); // prevent scrolling
    }
  }

  arrowKeyPress(event) {
    if (this.responseListIncludes(document.activeElement)) {
      this.reorderElement(this.response_sortable, document.activeElement, event.code);
    } else if (this.answerListIncludes(document.activeElement)) {
      this.reorderElement(this.answer_sortable, document.activeElement, event.code);
    }
  }

  spaceKeyPress(event) {
    if (this.responseListIncludes(document.activeElement)) {
      this.moveElement(this.responseListTarget, this.answerListTarget, document.activeElement);
      this.updateAnswerList();
    } else if (this.answerListIncludes(document.activeElement)) {
      this.moveElement(this.answerListTarget, this.responseListTarget, document.activeElement);
      this.updateResponseList();
    }
  }

  responseListIncludes(element) {
    return Array.from(this.responseListTarget.getElementsByTagName("li")).includes(element);
  }

  answerListIncludes(element) {
    return Array.from(this.answerListTarget.getElementsByTagName("li")).includes(element);
  }

  moveElement(source_list, target_list, element) {
    let e = source_list.removeChild(element);
    target_list.appendChild(e);
  }

  reorderElement(sortable, element, direction) {
    if (["ArrowUp", "ArrowDown"].includes(direction) == false ) {
      return false
    }
    if (typeof(element.dataset.sortableId) == "undefined") {
      return false
    }
    let order = sortable.toArray()
    let sortableId = element.dataset.sortableId // `dataIdAttr` from sortable config
    let index = order.indexOf(sortableId)
    if ((index == 0 && direction == "ArrowUp") || (index == order.length - 1 && direction == "ArrowDown")) {
      return false
    }
    // remove the element from the order array
    order.splice(index, 1)
    // insert the element at the correct position in the order array
    if (direction == "ArrowDown") {
      order.splice(index + 1, 0, sortableId)
    } else if (direction == "ArrowUp") {
      order.splice(index - 1, 0, sortableId)
    }
    // remove elements from the list and replace them in the desired order
    let list = sortable.el
    let elementsInOrder = [];
    for (let i = 0; i < order.length; i++) {
      let e = document.getElementById(order[i]);
      if (e) {
        elementsInOrder.push(e);
      }
    }
    while (list.firstChild) {
      list.removeChild(list.firstChild);
    }
    for (let i = 0; i < elementsInOrder.length; i++) {
      list.appendChild(elementsInOrder[i]);
    }
  }
}
