import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static get targets() {
    return ["list"];
  }

  connect() {
    this.sortable_list = Sortable.create(this.listTarget, {
      dataIdAttr: "data-sortable-id",
      animation: 300,
      ghostClass: "is-blue-1",
      handle: ".handle",
      dragClass: "dragging",
      group: "sortable-list",
    });
  }

  keyPress(event) {
    if (["ArrowUp", "ArrowDown"].includes(event.code) && document.activeElement.tagName == "LI") {
      this.reorderElement(document.activeElement, event.code)
      event.preventDefault(); // prevent scrolling
    }
  }

  reorderElement(element, direction) {
    if (["ArrowUp", "ArrowDown"].includes(direction) == false ) {
      return false
    }
    if (typeof(element.dataset.sortableId) == "undefined") {
      return false
    }
    let order = this.sortable_list.toArray()
    let sortableId = element.dataset.sortableId // `dataIdAttr` from sortable config
    let index = order.indexOf(sortableId)
    if ((index == 0 && direction == "ArrowUp") || (index == order.length - 1 && direction == "ArrowDown")) {
      return false
    }
    // remove the element from the order array
    order.splice(index, 1)
    // insert the element at the correct position in the order array
    if (direction == "ArrowDown") {
      order.splice(index + 1, 0, sortableId)
    } else if (direction == "ArrowUp") {
      order.splice(index - 1, 0, sortableId)
    }
    // sort the elements according to the new order array
    this.sortable_list.sort(order, true)
  }
}
