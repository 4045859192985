import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["submitButton", "checkbox", "continueButton", "textField"];
  }

  static values = {
    continueButtonPath: String
  }

  toggleSubmitForTextField(event) {
    if (this.textFieldTarget.value.length > 0 && !this.textFieldTarget.matches(":invalid")) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  toggleSubmitForCheckboxes(event) {
    let selection = false;
    this.checkboxTargets.forEach( (e) => { if (e.checked) { selection = true; } });
    if (selection) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  }

  enableSubmit(event) {
    this.submitButtonTargets.forEach( (e) => e.removeAttribute("disabled"));
  }

  disableSubmit(event) {
    this.submitButtonTargets.forEach( (e) => e.setAttribute("disabled", "disabled"));
  }

  submitAnswerOnPointerUp(event) {
    const assessmentForm = document.getElementById('assessment-form');
    Rails.fire(assessmentForm, 'submit');
  }

  update() {
    this.submitButtonTargets.forEach( (e) => e.setAttribute("disabled", "disabled"));
    this.submitButtonTargets.forEach( (e) => e.classList.add("is-loading"));
    const assessmentForm = document.getElementById('assessment-form');
    Rails.fire(assessmentForm, 'submit');
  }

  continue() {
    this.continueButtonTarget.setAttribute("disabled", "disabled");
    this.continueButtonTarget.classList.add("is-loading");
    this.continueButtonTarget.click();
  }

  // Enable the 'enter' key to submit a response and continue to the next item
  keyPress(event) {
    if (event.code == "Enter") {
      if (this.hasSubmitButtonTarget && this.submitButtonTarget.disabled) {
        return;
      } else if (this.hasSubmitButtonTarget) {
        this.update();
      } else if (this.hasContinueButtonTarget) {
        this.continue();
      }
    }

    if (event.code == "Space" && document.activeElement.tagName == "LABEL") {
      // Prevent scrolling
      event.preventDefault();

      // Find the input field for the focused label
      let inputId = document.activeElement.getAttribute("for");
      let input = document.getElementById(inputId);

      // Toggle the selection of the input field
      if (event.target.dataset.itemType == "SingleSelect") {
        const inputs = document.querySelectorAll("input");
        inputs.forEach( (i) => { i.removeAttribute("checked"); });
        input.toggleAttribute("checked", true);
      } else {
        input.toggleAttribute("checked");
      }

      // Trigger the associated data-action
      let dataAction = input.getAttribute("data-action").trim();
      if (dataAction == "assessments#enableSubmit") {
        this.enableSubmit();
      } else if (dataAction == "assessments#toggleSubmitForCheckboxes") {
        this.toggleSubmitForCheckboxes();
      }
    }
  }

  getNextItem() {
    Rails.ajax({
      type: "POST",
      url: this.continueButtonPathValue
    })
  }

  continueToResults() {
    document.location.href = this.continueButtonPathValue
  }
}
