import library from "./fontaesome_svg_core";

import {
  faAbacus,
  faAnalytics,
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBookmark,
  faBookOpen,
  faBookSpells,
  faBrain,
  faBrowser,
  faCalculator,
  faCalculatorAlt,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarTimes,
  faCaretRight,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faChartPie,
  faChartScatter,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faClipboardListCheck,
  faClock,
  faCloud,
  faCodeBranch,
  faComment,
  faCommentsAlt,
  faCompass,
  faCopy,
  faCropAlt,
  faCrown,
  faDatabase,
  faDiceD6,
  faDotCircle,
  faDraftingCompass,
  faDrawSquare,
  faEnvelope,
  faEnvelopeOpen,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faEye,
  faFeather,
  faFile,
  faFileCertificate,
  faFileChartLine,
  faFileChartPie,
  faFileExcel,
  faFileImage,
  faFileImport,
  faFilePdf,
  faFilePowerpoint,
  faFileSignature,
  faFileSpreadsheet,
  faFileUpload,
  faFileUser,
  faFilter,
  faFingerprint,
  faFire,
  faFolderTree,
  faGlobe,
  faGraduationCap,
  faHammer,
  faHeart,
  faIdCard,
  faInfoCircle,
  faIntegral,
  faKey,
  faKeynote,
  faLambda,
  faLaptopCode,
  faLightbulbOn,
  faListOl,
  faLocationCircle,
  faLock,
  faLongArrowDown,
  faLongArrowRight,
  faMap,
  faMapMarker,
  faMapSigns,
  faMindShare,
  faObjectGroup,
  faPaperPlane,
  faPause,
  faPhone,
  faPi,
  faPlay,
  faPlus,
  faPollPeople,
  faPresentation,
  faProjectDiagram,
  faQuestion,
  faRandom,
  faRedo,
  faRepeat,
  faRoad,
  faRulerTriangle,
  faScrewdriver,
  faSearch,
  faServer,
  faShapes,
  faShield,
  faSigma,
  faSitemap,
  faSlidersH,
  faSpider,
  faSpinner,
  faSquareRoot,
  faStopwatch,
  faSuperscript,
  faSyncAlt,
  faTable,
  faTachometer,
  faTachometerAlt,
  faTags,
  faTelescope,
  faTheta,
  faThLarge,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTools,
  faTrophy,
  faUniversity,
  faUser,
  faUserAlt,
  faUserChart,
  faUserFriends,
  faUserRobot,
  faUsers,
  faUsersClass,
  faWarehouseAlt,
  faWrench,
} from "@fortawesome/pro-solid-svg-icons"

library.add(
  faAbacus,
  faAnalytics,
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBookmark,
  faBookOpen,
  faBookSpells,
  faBrain,
  faBrowser,
  faCalculator,
  faCalculatorAlt,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarTimes,
  faCaretRight,
  faChartArea,
  faChartBar,
  faChartLine,
  faChartNetwork,
  faChartPie,
  faChartScatter,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faClipboardListCheck,
  faClock,
  faCloud,
  faCodeBranch,
  faComment,
  faCommentsAlt,
  faCompass,
  faCopy,
  faCropAlt,
  faCrown,
  faDatabase,
  faDiceD6,
  faDotCircle,
  faDraftingCompass,
  faDrawSquare,
  faEnvelope,
  faEnvelopeOpen,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faEye,
  faFeather,
  faFile,
  faFileCertificate,
  faFileChartLine,
  faFileChartPie,
  faFileExcel,
  faFileImage,
  faFileImport,
  faFilePdf,
  faFilePowerpoint,
  faFileSignature,
  faFileSpreadsheet,
  faFileUpload,
  faFileUser,
  faFilter,
  faFingerprint,
  faFire,
  faFolderTree,
  faGlobe,
  faGraduationCap,
  faHammer,
  faHeart,
  faIdCard,
  faInfoCircle,
  faIntegral,
  faKey,
  faKeynote,
  faLambda,
  faLaptopCode,
  faLightbulbOn,
  faListOl,
  faLocationCircle,
  faLock,
  faLongArrowDown,
  faLongArrowRight,
  faMap,
  faMapMarker,
  faMapSigns,
  faMindShare,
  faObjectGroup,
  faPaperPlane,
  faPause,
  faPhone,
  faPi,
  faPlay,
  faPlus,
  faPollPeople,
  faPresentation,
  faProjectDiagram,
  faQuestion,
  faRandom,
  faRedo,
  faRepeat,
  faRoad,
  faRulerTriangle,
  faScrewdriver,
  faSearch,
  faServer,
  faShapes,
  faShield,
  faSigma,
  faSitemap,
  faSlidersH,
  faSpider,
  faSpinner,
  faSquareRoot,
  faStopwatch,
  faSuperscript,
  faSyncAlt,
  faTable,
  faTachometer,
  faTachometerAlt,
  faTags,
  faTelescope,
  faTheta,
  faThLarge,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faToolbox,
  faTools,
  faTrophy,
  faUniversity,
  faUser,
  faUserAlt,
  faUserChart,
  faUserFriends,
  faUserRobot,
  faUsers,
  faUsersClass,
  faWarehouseAlt,
  faWrench,
)
