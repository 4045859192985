import bulmaCollapsible from '@creativebulma/bulma-collapsible';
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static get targets() {
        return ['collapsible'];
    }

    collapsible = new bulmaCollapsible(this.collapsibleTarget);

    toggleCollapsible(event) {
        if (this.collapsible.collapsed()) {
            this.collapsible.open();
        } else {
            this.collapsible.close();
        }
    }
}
