import library from "./fontaesome_svg_core";

import {
  faAbacus as fadAbacus,
  faAnalytics as fadAnalytics,
  faBookSpells as fadBookSpells,
  faBrowser as fadBrowser,
  faBuilding as fadBuilding,
  faCalculator as fadCalculator,
  faCalculatorAlt as fadCalculatorAlt,
  faChartArea as fadChartArea,
  faChartBar as fadChartBar,
  faChartLine as fadChartLine,
  faChartNetwork as fadChartNetwork,
  faChartPie as fadChartPie,
  faChartScatter as fadChartScatter,
  faClipboardListCheck as fadClipboardListCheck,
  faCloud as fadCloud,
  faCodeBranch as fadCodeBranch,
  faCommentsAlt as fadCommentsAlt,
  faCompass as fadCompass,
  faCopy as fadCopy,
  faCropAlt as fadCropAlt,
  faDatabase as fadDatabase,
  faDiceD6 as fadDiceD6,
  faDraftingCompass as fadDraftingCompass,
  faDrawSquare as fadDrawSquare,
  faFeather as fadFeather,
  faFileCertificate as fadFileCertificate,
  faFileChartLine as fadFileChartLine,
  faFileChartPie as fadFileChartPie,
  faFileImport as fadFileImport,
  faFileSignature as fadFileSignature,
  faFileSpreadsheet as fadFileSpreadsheet,
  faFileUser as fadFileUser,
  faFingerprint as fadFingerprint,
  faFolderTree as fadFolderTree,
  faGhost as fadGhost,
  faGlobe as fadGlobe,
  faGraduationCap as fadGraduationCap,
  faHammer as fadHammer,
  faHeartRate as fadHeartRate,
  faIdCard as fadIdCard,
  faIntegral as fadIntegral,
  faKeynote as fadKeynote,
  faLambda as fadLambda,
  faLaptopCode as fadLaptopCode,
  faLightbulbOn as fadLightbulbOn,
  faListOl as fadListOl,
  faLocationCircle as fadLocationCircle,
  faMap as fadMap,
  faMapSigns as fadMapSigns,
  faMindShare as fadMindShare,
  faObjectGroup as fadObjectGroup,
  faPi as fadPi,
  faPollPeople as fadPollPeople,
  faPresentation as fadPresentation,
  faProjectDiagram as fadProjectDiagram,
  faRandom as fadRandom,
  faRepeat as fadRepeat,
  faRoad as fadRoad,
  faRulerTriangle as fadRulerTriangle,
  faSchool as fadSchool,
  faScrewdriver as fadScrewdriver,
  faServer as fadServer,
  faShapes as fadShapes,
  faSigma as fadSigma,
  faSitemap as fadSitemap,
  faSlidersH as fadSlidersH,
  faSnooze as fadSnooze,
  faSpider as fadSpider,
  faSquareRoot as fadSquareRoot,
  faSuperscript as fadSuperscript,
  faTable as fadTable,
  faTachometerAlt as fadTachometerAlt,
  faTags as fadTags,
  faTelescope as fadTelescope,
  faTheta as fadTheta,
  faToolbox as fadToolbox,
  faTools as fadTools,
  faUniversity as fadUniversity,
  faUserAlt as fadUserAlt,
  faUserChart as fadUserChart,
  faUserRobot as fadUserRobot,
  faUsers as fadUsers,
  faUserSecret as fadUserSecret,
  faUsersClass as fadUsersClass,
  faWarehouseAlt as fadWarehouseAlt,
  faWrench as fadWrench,
} from "@fortawesome/pro-duotone-svg-icons"

library.add(
  fadAbacus,
  fadAnalytics,
  fadBookSpells,
  fadBrowser,
  fadBuilding,
  fadCalculator,
  fadCalculatorAlt,
  fadChartArea,
  fadChartBar,
  fadChartLine,
  fadChartNetwork,
  fadChartPie,
  fadChartScatter,
  fadClipboardListCheck,
  fadCloud,
  fadCodeBranch,
  fadCommentsAlt,
  fadCompass,
  fadCopy,
  fadCropAlt,
  fadDatabase,
  fadDiceD6,
  fadDraftingCompass,
  fadDrawSquare,
  fadFeather,
  fadFileCertificate,
  fadFileChartLine,
  fadFileChartPie,
  fadFileImport,
  fadFileSignature,
  fadFileSpreadsheet,
  fadFileUser,
  fadFingerprint,
  fadFolderTree,
  fadGhost,
  fadGlobe,
  fadGraduationCap,
  fadHammer,
  fadHeartRate,
  fadIdCard,
  fadIntegral,
  fadKeynote,
  fadLambda,
  fadLaptopCode,
  fadLightbulbOn,
  fadListOl,
  fadLocationCircle,
  fadMap,
  fadMapSigns,
  fadMindShare,
  fadObjectGroup,
  fadPi,
  fadPollPeople,
  fadPresentation,
  fadProjectDiagram,
  fadRandom,
  fadRepeat,
  fadRoad,
  fadRulerTriangle,
  fadSchool,
  fadScrewdriver,
  fadServer,
  fadShapes,
  fadSigma,
  fadSitemap,
  fadSlidersH,
  fadSnooze,
  fadSpider,
  fadSquareRoot,
  fadSuperscript,
  fadTable,
  fadTachometerAlt,
  fadTags,
  fadTelescope,
  fadTheta,
  fadToolbox,
  fadTools,
  fadUniversity,
  fadUserAlt,
  fadUserChart,
  fadUserRobot,
  fadUsers,
  fadUserSecret,
  fadUsersClass,
  fadWarehouseAlt,
  fadWrench,
)
